import * as firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FCM_API_KEY,
  authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FCM_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FCM_APP_ID,
  measurementId: process.env.VUE_APP_FCM_MEASUREMENT_ID
};

export const firebaseNotificationsApp = firebase.initializeApp(
  firebaseConfig,
  "biew-front-notification"
);
export const messaging = firebase.messaging(firebaseNotificationsApp);

export default {
  messaging,
  firebaseNotificationsApp
};
