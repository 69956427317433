<template>
  <b-modal
    :active="show"
    trap-focus
    has-modal-card
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    class="custom-modal"
    @update:active="$emit('update:show', $event)"
    @close="$emit('cancel')"
  >
    <div class="modal-card custom-modal-card">
      <div class="modal-content rounded-lg bg-white p-4">
        <div class="modal-title text-gray-600 text-left text-lg font-bold">
          <slot name="title">
            {{ title }}
          </slot>
        </div>
        <div class="modal-body text-gray-600 text-left text-lg">
          <div class="container-details scrollable-content">
            <!-- ORDER DATA -->
            <div class="grid grid-cols-3">
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.client_name") }}
                </label>
                <label class="detail-text">
                  {{ order.customerName }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.phone") }}
                </label>
                <label class="detail-text">
                  {{ order.phoneNumber }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.email") }}
                </label>
                <label class="detail-text">
                  {{ order.customerEmail }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.order_number") }}
                </label>
                <label class="detail-text">
                  {{ `#${order.orderId}` }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.date") }}
                </label>
                <label class="detail-text">
                  {{ formatDate(order.createdAt) }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.status") }}
                </label>
                <label class="detail-text">
                  <BSelectOrderStatus
                    :status="order.status"
                    :confirm-change="true"
                    :delivery-type="order.deliveryType"
                  />
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.payment") }}
                </label>
                <label class="detail-text">
                  {{ $t("payment_types." + order.paymentType) }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.delivery") }}
                </label>
                <label class="detail-text">
                  {{ $t("delivery_types." + order.deliveryType) }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.zip_code") }}
                </label>
                <label class="detail-text">
                  {{ orderZipCode }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.address") }}
                </label>
                <label class="detail-text">
                  {{ orderAddress }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.city") }}
                </label>
                <label class="detail-text">
                  {{ orderCity }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.state-province") }}
                </label>
                <label class="detail-text">
                  {{ orderState }}
                </label>
              </div>
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.country-region") }}
                </label>
                <label class="detail-text">
                  {{ orderCountry }}
                </label>
              </div>
            </div>

            <!-- ORDER NOTES -->
            <div class="grid grid-cols-1 grid-rows-1">
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.notes") }}
                </label>
                <label class="detail-text">
                  {{ order.notes }}
                </label>
              </div>
            </div>

            <!-- ORDER ITEMS -->
            <div>
              <label class="detail-label ml-1 mt-5">
                {{ $t("modal_order_details.order_details.order") }}
              </label>
              <div
                v-for="(item, rowIndex) in order.items"
                :key="rowIndex"
                class="grid grid-cols-3"
              >
                <div class="label-container">
                  <label class="detail-text">
                    {{ item ? item.name : "N/A" }}
                  </label>
                  <div class="label-container-items">
                    <label class="detail-label-items">
                      {{ `${$t("modal_order_details.order_details.size")}: ` }}
                    </label>
                    <label class="detail-text-items">
                      {{ item ? item.size : "N/A" }}
                    </label>
                  </div>
                  <div class="label-container-items">
                    <label class="detail-label-items">
                      {{ `${$t("modal_order_details.order_details.price")}: ` }}
                    </label>
                    <label class="detail-text-items">
                      {{ `$${item ? item.price : "N/A"}` }}
                    </label>
                  </div>
                </div>
                <div class="label-container-items">
                  <label class="detail-label-items">
                    {{
                      `${$t("modal_order_details.order_details.quantity")}: `
                    }}
                  </label>
                  <label class="detail-text-items">
                    {{ item ? item.quantity : "N/A" }}
                  </label>
                </div>
                <div class="label-container-items">
                  <label class="detail-label-items">
                    {{
                      `${$t("modal_order_details.order_details.subtotal")}: `
                    }}
                  </label>
                  <label class="detail-text-items">
                    {{ subtotalPrice(item.quantity, item.price) }}
                  </label>
                </div>
              </div>
              <div class="grid grid-cols-3 ">
                <div class="label-container">
                  <label class="detail-label">
                    {{ $t("modal_order_details.order_details.delivery_cost") }}
                  </label>
                </div>
                <div></div>
                <div class="label-container-items">
                  <label class="detail-label-items">
                    {{
                      `${$t("modal_order_details.order_details.subtotal")}: `
                    }}
                  </label>
                  <label class="detail-text detail-text-items">
                    {{ `$${order.shippingCost}` }}
                  </label>
                </div>
              </div>
            </div>

            <!-- ORDER TOTAL -->
            <div class="grid grid-cols-3 label-container-total justify-between">
              <div class="label-container">
                <label class="detail-label">
                  {{ $t("modal_order_details.order_details.total") }}
                </label>
              </div>
              <div></div>

              <div class="label-container-items text-right">
                <label class="detail-label-items">
                  {{ `${$t("modal_order_details.order_details.total")}: ` }}
                </label>
                <label class="detail-text-items">
                  {{ `$${order.total}` }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div :class="['modal-footer w-full mt-4 flex', buttonAlignmentClass]">
          <slot name="footer">
            <div class="cancel-order-button">
              <span class="block mb-2">
                {{ $t("sales_website.cancel_message") }}
              </span>
              <button
                type="button"
                class="text-red-700 hover:text-white border border-red-700 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2"
                @click="cancelOrder"
              >
                {{ $t("sales_website.cancel_order_button") }}
              </button>
            </div>
            <div class="flex justify-center items-center space-x-4">
              <button
                v-if="!cancelButtonDisabled"
                class="cancel-button text-gray-600 hover:text-gray-700 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded shadow-md font-bold"
                @click="$emit('cancel')"
              >
                {{ cancelText }}
              </button>
              <button
                class="text-white hover:text-white font-bold px-4 py-2 rounded shadow-md"
                :class="acceptBtnClass"
                @click="$emit('accept')"
              >
                {{ acceptText }}
              </button>
            </div>
          </slot>
        </div>
      </div>
      <ConfirmationModal
        :show="isConfirmationModalVisible"
        :title="$t('modal_confirmation_status.confirmation_title')"
        :accept-text="$t('modal_confirmation_status.confirm')"
        :cancel-text="$t('modal_confirmation_status.cancel')"
        accept-text-style-type="dark"
        @cancel="closeConfirmationModal"
        @accept="confirmCancelOrder(order.orderId)"
      >
        <div class="confirmation-modal-content">
          {{
            $t("modal_confirmation_status.confirmation_message", {
              newStatus: $t("sales_website.cancelled")
            })
          }}
        </div>
      </ConfirmationModal>
    </div>
  </b-modal>
</template>

<script>
import ConfirmationModal from "./ConfirmationModal.vue";
import { ORDER_STATUS } from "../utils/constants";
import { dateFormart } from "../utils/helpers";
import BSelectOrderStatus from "./BSelectOrderStatus.vue";
export default {
  name: "OrderDetailsModal",
  components: {
    BSelectOrderStatus,
    ConfirmationModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Alert"
    },
    cancelText: {
      type: String,
      default: "Cancel"
    },
    acceptText: {
      type: String,
      default: "Accept"
    },
    acceptTextStyleType: {
      type: String,
      default: "danger",
      validator: function(value) {
        return ["dark", "danger"].includes(value);
      }
    },
    cancelButtonDisabled: {
      type: Boolean,
      default: false
    },
    centerButtons: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      required: true
    },
    onCancelOrder: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      isConfirmationModalVisible: false
    };
  },
  computed: {
    orderZipCode() {
      return this.order?.deliveryInformation?.zipCode || "N/A";
    },
    orderAddress() {
      return this.order?.deliveryInformation?.address || "N/A";
    },
    orderCity() {
      return this.order?.deliveryInformation?.city || "N/A";
    },
    orderState() {
      return this.order?.deliveryInformation?.state || "N/A";
    },
    orderCountry() {
      return this.order?.deliveryInformation?.country || "N/A";
    },
    acceptBtnClass() {
      if (this.acceptTextStyleType === "danger") {
        return "bg-red-500 hover:bg-red-700";
      } else if (this.acceptTextStyleType === "dark") {
        return "bg-gray-800 hover:bg-gray-700";
      }
      return "";
    },
    buttonAlignmentClass() {
      return this.centerButtons ? "justify-center" : "justify-end";
    }
  },
  methods: {
    formatDate(date) {
      return dateFormart(date);
    },
    subtotalPrice(quantity, price) {
      if (!quantity || !price) {
        return "N/A";
      }
      return `$${quantity * price}`;
    },
    closeConfirmationModal() {
      this.isConfirmationModalVisible = false;
    },
    confirmCancelOrder(id) {
      this.onCancelOrder(id, ORDER_STATUS.CANCELLED);
      this.closeConfirmationModal();
    },
    cancelOrder() {
      this.isConfirmationModalVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-modal {
  width: auto;
}

.custom-modal-card {
  width: 90vh;
}

.modal-content {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: white;
  padding: 2rem;
}

.modal-title {
  color: #4a5568;
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.modal-body {
  color: #4a5568;
  text-align: left;
  font-size: 1.125rem;
}

.modal-footer {
  width: 100%;
  gap: 100px;
  display: flex;
  margin-bottom: 2px;
}

.cancel-button {
  color: #4a5568;
  background-color: #e2e8f0;
  padding: 0.5px 1px;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.cancel-button:hover {
  color: #2d3748;
}

.container-details {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
}

.label-container {
  padding: 5px;
}

.label-container-total {
  padding: 5px;
  border-top: solid #303034;
}

.detail-label {
  display: block;
  color: #303034;
  font-size: 1rem;
}

.detail-text {
  display: block;
  line-height: 1.5;
  color: #303034;
  font-weight: 500;
}

.label-container-items {
  display: flex;
  align-items: center;
}

.detail-label-items {
  margin-right: 10px;
  display: block;
  color: #303034;
  font-size: 1rem;
}

.detail-text-items {
  margin-right: 10px;
  display: block;
  font-weight: 500;
  line-height: 1.5;
  color: #303034;
  font-size: 1rem;
}
</style>
