import axios from "axios";

const endpoint = "/api/orders";

export default {
  /**
   * Sends a GET request to the `/api/orders` endpoint with the specified query parameters.
   * @param query - An object that contains filters and search criteria for the request.
   * @returns The result of the axios GET request.
   */
  getOrders(websiteId, filters) {
    return axios.get(`${endpoint}/websiteId/${websiteId}`, {
      params: filters
    });
  },

  /**
   * Sends a GET request to the `/api/orders/orderId/:id` endpoint. Retrieves order details
   * using the provided `orderId`.
   * @param orderId - Unique identifier for the order.
   * @returns The result of the axios GET request.
   */
  getOrderDetails(orderId) {
    return axios.get(`${endpoint}/${orderId}`);
  },

  /**
   * `updateStatusOrder` sends a PATCH request to update the status of an order.
   * @param orderId - The unique identifier of the order to update.
   * @param body - The body with necessary data to assign to the order.
   * @returns The result of the axios GET request.
   */
  updateStatusOrder(orderId, body) {
    return axios.patch(`${endpoint}/status/${orderId}`, body);
  },

  /**
   * The getSales function retrieves sales data for a specific website ID using an Axios GET request.
   * @param websiteId - The `websiteId` parameter is the unique identifier for a specific website.
   * @returns The result of the axios GET request.
   */
  getSales(websiteId) {
    return axios.get(`${endpoint}/sales/websiteId/${websiteId}`);
  }
};
