import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"; // Asegúrate de importar el ícono correcto

export const deliveryTypeIcon = deliveryType => {
  switch (deliveryType) {
    case "home_delivery":
      return <FontAwesomeIcon icon="fa-solid fa-truck-fast" />; // Usando Font Awesome
    case "pick_up_in_store":
      return <FontAwesomeIcon icon="fa-solid fa-store" />; // Usando Font Awesome
    default:
      return null; // Retorna el valor por defecto si no coincide
  }
};

export const dateFormart = date => {
  return moment(date).format("DD/MM/YYYY HH:mm") + " hrs";
};

export const dateFormartCustomers = date => {
  if (!date) {
    return "";
  }
  return moment(date).format("DD/MM/YYYY");
};

export const orderFormat = items => {
  const quantity = items.reduce((acc, item) => acc + item.quantity, 0);

  const orderItems =
    items
      .map(item => `${item.name} ${item.description}`)
      .join(", ")
      .slice(0, 100) + "...";

  return { orderItems, quantity };
};
