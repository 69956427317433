<template>
  <b-modal
    :active="show"
    trap-focus
    has-modal-card
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    style="width: auto"
    @update:active="$emit('update:show', $event)"
    @close="$emit('cancel')"
  >
    <div class="modal-card" style="width: auto">
      <div class="flex flex-col rounded-lg bg-white p-4">
        <div class="text-gray-600 text-left text-lg font-bold">
          {{ title }}
        </div>
        <div class="text-gray-600 text-left text-lg">
          <slot> </slot>
        </div>
        <div class="w-full mt-4 flex justify-end">
          <slot name="footer">
            <button
              class="text-gray-600 hover:text-gray-700 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded shadow-md font-bold"
              @click="$emit('cancel')"
            >
              {{ cancelText }}
            </button>
            <button
              class="ml-4 text-white hover:text-white font-bold px-4 py-2 rounded shadow-md"
              :class="acceptBtnClass"
              @click="$emit('accept')"
            >
              {{ acceptText }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmationModal",

  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Alert"
    },
    cancelText: {
      type: String,
      default: "Cancel"
    },
    acceptText: {
      type: String,
      default: "Accept"
    },
    acceptTextStyleType: {
      type: String,
      default: "danger",
      validator: function(value) {
        return ["dark", "danger"].includes(value);
      }
    }
  },
  computed: {
    acceptBtnClass() {
      if (this.acceptTextStyleType === "danger") {
        return "bg-red-500 hover:bg-red-700";
      } else if (this.acceptTextStyleType === "dark") {
        return "bg-gray-800 hover:bg-gray-700";
      }
      return "";
    }
  }
};
</script>
