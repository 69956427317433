<template>
  <div>
    <select
      v-model="selectedStatus"
      :class="statusClass"
      @change="onChangeStatus"
    >
      <option :value="ORDER_STATUS.PENDING">
        {{ $t("sales_website.pending") }}
      </option>
      <option :value="ORDER_STATUS.IN_PREPARATION">
        {{ $t("sales_website.in_preparation") }}
      </option>
      <option :value="ORDER_STATUS.READY">
        {{ $t("sales_website.ready") }}
      </option>
      <option :value="ORDER_STATUS.ON_THE_WAY">
        {{ $t("sales_website.on_the_way") }}
      </option>
      <option :value="ORDER_STATUS.DELIVERED">
        {{ $t("sales_website.delivered") }}
      </option>
      <option :value="ORDER_STATUS.CANCELLED">
        {{ $t("sales_website.cancelled") }}
      </option>
    </select>

    <ConfirmationModal
      :show="isConfirmationModalVisible"
      :title="$t('modal_confirmation_status.confirmation_title')"
      :accept-text="$t('modal_confirmation_status.confirm')"
      :cancel-text="$t('modal_confirmation_status.cancel')"
      accept-text-style-type="dark"
      @cancel="cancelChangeStatus"
      @accept="confirmChangeStatus"
    >
      <div class="confirmation-modal-content">
        {{
          $t("modal_confirmation_status.confirmation_message", {
            newStatus: $t(`sales_website.${tempValue}`)
          })
        }}
      </div>
    </ConfirmationModal>
  </div>
</template>

<script>
import { DELIVERY_TYPES, ORDER_STATUS } from "../utils/constants";
import ConfirmationModal from "./ConfirmationModal.vue";

export default {
  components: {
    ConfirmationModal
  },
  props: {
    status: {
      type: String,
      required: false,
      default: ""
    },
    onChange: {
      type: Function,
      required: false,
      default: () => {}
    },
    confirmChange: {
      type: Boolean,
      required: false,
      default: false
    },
    deliveryType: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      selectedStatus: this.status,
      tempValue: null,
      isConfirmationModalVisible: false,
      ORDER_STATUS
    };
  },
  computed: {
    statusClass() {
      return Object.values(ORDER_STATUS).includes(this.selectedStatus)
        ? this.selectedStatus
        : "";
    }
  },
  watch: {
    status(newStatus) {
      this.selectedStatus = newStatus;
    }
  },
  methods: {
    updateStatus() {
      this.onChange(this.selectedStatus);
    },
    onChangeStatus() {
      if (this.status === ORDER_STATUS.CANCELLED) {
        this.$buefy.notification.open({
          message: this.$t("status_change_alerts.cancelled"),
          type: "is-warning"
        });
        this.selectedStatus = this.status;
        return;
      }
      if (
        this.selectedStatus === ORDER_STATUS.READY &&
        this.deliveryType !== DELIVERY_TYPES.PICK_UP_IN_STORE
      ) {
        this.$buefy.notification.open({
          message: this.$t("status_change_alerts.ready"),
          type: "is-warning"
        });
        this.selectedStatus = this.status;
        return;
      }

      if (
        this.selectedStatus === ORDER_STATUS.ON_THE_WAY &&
        this.deliveryType !== DELIVERY_TYPES.HOME_DELIVERY
      ) {
        this.$buefy.notification.open({
          message: this.$t("status_change_alerts.on_the_way"),
          type: "is-warning"
        });
        this.selectedStatus = this.status;
        return;
      }

      if (this.confirmChange) {
        this.tempValue = this.selectedStatus;
        this.isConfirmationModalVisible = true;
      } else {
        this.updateStatus();
      }
    },
    closeConfirmationModal() {
      this.isConfirmationModalVisible = false;
    },
    confirmChangeStatus() {
      this.selectedStatus = this.tempValue;
      this.updateStatus();
      this.closeConfirmationModal();
    },
    cancelChangeStatus() {
      this.selectedStatus = this.status;
      this.closeConfirmationModal();
    }
  }
};
</script>

<style lang="scss" scoped>
select {
  width: auto;
  height: 30px;
  border-radius: 5px;
  padding-left: 5px;
}

.pending {
  color: #fff;
  background-color: #aaaaaa;
}

.in_preparation {
  color: #846300;
  background-color: #ffefbf;
}

.ready {
  color: #0071b8;
  background-color: #b2e1ff;
}

.on_the_way {
  color: #3e2f44;
  background-color: #e8b2ff;
}

.delivered {
  color: #00743f;
  background-color: #acffd9;
}

.cancelled {
  color: #790000;
  background-color: #ffd6d6;
}

.confirmation-modal-content {
  height: 100px;
  width: 300px;
  text-align: justify;
  justify-content: center;
  align-content: center;
}
</style>
