<template>
  <span class="text-sm px-2 py-1 rounded" :class="`status_${status}`">
    {{ $t(`sales_website.${status}`) }}
  </span>
</template>
<style lang="scss">
.status_pending {
  background: #ceced0;
  color: #000000;
  opacity: 0.75;
}
.status_in_preparation {
  background: #ffefbf;
  color: #846300;
  opacity: 1;
}
.status_on_the_way {
  background: #ff935c;
  color: #000000;
  opacity: 0.75;
}
.status_ready {
  background: #b2e1ff;
  color: #0071b8;
}
.status_delivered {
  background: #acffd9;
  color: #000000;
  opacity: 0.75;
}
.status_cancelled {
  background: #ffd6d6;
  color: #790000;
  opacity: 0.75;
}
</style>
<script>
export default {
  name: "OrderStatus",
  props: {
    status: {
      type: String,
      default: ""
    }
  },
  methods: {}
};
</script>
