<template>
  <div
    class="p-2 flex border-black p-1 flex-col"
    :class="!visited ? 'new-notification' : ''"
    @click.stop="$emit('on-click-notification')"
  >
    <!-- TOP -->
    <div class="flex justify-between">
      <div>
        <span>
          <strong>
            {{ title }}
          </strong>
        </span>
      </div>
      <div
        class="p-1 rounded-full hover:bg-gray-200 w-7 h-7 flex items-center justify-center 
        transitiona-all duration-200 ease-in-out"
        @click.stop="$emit('on-close-notification')"
      >
        <b-icon name="times" class="text-black" />
      </div>
    </div>
    <!-- Website name -->
    <div class="flex justify-between items-center mt-3">
      <div class="text-sm self-start">
        <span class="text-black"> {{ $t("builder.website") }}: </span>
      </div>
      <div>
        <span class="text-black inline-block cls-webste-name break-words">
          <strong>
            {{ websiteName }}
          </strong>
        </span>
      </div>
    </div>
    <!-- Message -->
    <div class="flex items-center mt-3">
      <p class="text-black">
        {{ body }}
      </p>
    </div>
    <!-- Estatus -->
    <div class="flex justify-between items-center mt-3">
      <div class="text-sm">
        <span class="text-black">
          {{ $t("status") }}
        </span>
      </div>
      <div>
        <order-status :status="status" />
      </div>
    </div>
    <hr class="notification-divider mt-2" />
  </div>
</template>
<script>
import OrderStatus from "./OrderStatus.vue";
import BIcon from "../BIcon.vue";
export default {
  components: {
    OrderStatus,
    BIcon
  },
  props: {
    status: {
      type: String,
      default: ""
    },
    websiteName: {
      type: String,
      default: ""
    },
    body: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    visited: {
      type: Boolean,
      default: true
    },
    onCloseNotification: {
      type: Function,
      default: () => undefined
    },
    onClickNotification: {
      type: Function,
      default: () => undefined
    }
  }
};
</script>
<style lang="scss">
.notification-divider {
  border-bottom: 2px solid #eaeaea;
}
.imgSmall {
  width: 32px;
  height: 32px;
  max-width: none !important;
  cursor: pointer;
}
.cls-webste-nam break-wordse {
  width: 200px;
}
.new-notification {
  background: rgba(255, 252, 210, 0.2);
}
</style>
