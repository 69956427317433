import axios from "axios";
const model = "notifications";
export default {
  getAdminNotifications() {
    return axios.get(`/api/${model}/admin-notifications`);
  },
  updateNotification(id, data) {
    return axios.patch(`/api/${model}/${id}`, data);
  },
  saveNotificationToken(data) {
    return axios.post(`/api/${model}/save-admin-messaging-token`, data);
  }
};
