export const MENU_OPTION_SUBSCRIPTION = "subscription";
export const currencyConfig = {
  mxn: ["es-MX", { style: "currency", currency: "MXN" }],
  usd: ["en-US", { style: "currency", currency: "USD" }]
};

export const ORDER_STATUS = {
  PENDING: "pending",
  IN_PREPARATION: "in_preparation",
  ON_THE_WAY: "on_the_way",
  READY: "ready",
  DELIVERED: "delivered",
  CANCELLED: "cancelled"
};

export const DELIVERY_TYPES = {
  PICK_UP_IN_STORE: "pick_up_in_store",
  HOME_DELIVERY: "home_delivery"
};
