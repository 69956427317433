<template>
  <div class="main-layout has-background-white-ter overflow-y-auto relative">
    <div class="has-background-black">
      <div class="container">
        <b-navbar type="is-black">
          <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
              <img class="logo" src="@/assets/logo-white.png" alt="logo" />
            </b-navbar-item>
          </template>
          <template slot="start">
            <b-navbar-item class="has-text-weight-bold" href="#">
              {{ navTitle ? navTitle : $t("navbar_titles.dashboard") }}
            </b-navbar-item>
          </template>
          <template slot="end">
            <b-navbar-item
              class="has-text-weight-bold"
              target="_blank"
              href="https://www.notion.so/9cb4119ddbc646949b794d6da494d5a8?v=7687ff496d9647598b5494a50219e60a"
            >
              {{ $t("builder.help") }}
              <b-icon name="question-circle" class="ml-3" />
            </b-navbar-item>
            <div class="relative flex items-center">
              <img
                class="h-8 w-8 rounded-full ml-2 border-2 border-white cursor-pointer"
                :src="userProfileImage"
                :alt="$t('builder.profile_image')"
                @click="toggleMenu"
                @error="userImageError = true"
              />
              <div
                v-show="showMenu"
                ref="userMenu"
                class="
                  absolute bg-white mt-40 shadow-md rounded-lg outline-none
                  left-0 lg:left-auto lg:right-0
                "
                tabindex="0"
                @focusout="closeMenu"
              >
                <div class="flex flex-col flex-grow profile_menu">
                  <menu-item
                    v-for="option of menuOptions"
                    :key="option.id"
                    v-bind="option"
                  ></menu-item>
                </div>
              </div>
            </div>
            <div class="mr-3 relative flex items-center mx-2">
              <div
                class="button-container py-4 lg:py-0 mx-0 lg:mx-3 cursor-pointer"
              >
                <div
                  class="btn text-black lg:text-white flex"
                  @click.stop="handleClickOpenNotificacions"
                >
                  <b-icon name="bell" class="text-xl" />
                </div>
                <span v-if="showBadge" id="spn-badge" class="badge"></span>
                <div
                  v-show="showNotifications"
                  ref="notificationsList"
                  class="cls-notifications-list absolute bg-white shadow-md rounded-lg outline-none left-0 lg:left-auto lg:right-0"
                  tabindex="1"
                  @focusout="closeNotifications"
                >
                  <div
                    v-if="formatedNotifications.length > 0"
                    class="flex flex-col"
                  >
                    <notification-item
                      v-for="notification in formatedNotifications"
                      :key="notification.id"
                      v-bind="notification"
                      @on-click-notification="
                        handleClickNotification(notification.id)
                      "
                      @on-close-notification="
                        handleClickCloseNotification(notification.id)
                      "
                    />
                  </div>
                  <div
                    v-else
                    class="p-2 flex flex-col justify-center items-center"
                  >
                    <img
                      src="@/assets/svg/notification-empty.svg"
                      class="py-3"
                      width="200"
                      height="auto"
                    />
                    <span class="empty-not-message">{{
                      $t("empty_notifications")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </b-navbar>
      </div>
    </div>
    <suscription-modal
      :activate="openSubscriptionModal"
      @close="openSubscriptionModal = false"
    />
    <order-details-modal
      :show="isModalVisible"
      :title="$t('modal_order_details.title')"
      cancel-button-disabled
      :accept-text="$t('modal_order_details.accept_text')"
      accept-text-style-type="dark"
      :center-buttons="true"
      :order="selectedOrder"
      @cancel="closeOrderDetailsModal"
      @accept="closeOrderDetailsModal"
    />
    <router-view></router-view>
    <b-loading
      :is-full-page="true"
      :active.sync="loading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import * as Sentry from "@sentry/browser";
import { messaging } from "../plugins/firebase/notifications";
import ApiAuth from "@/services/auth";
import BIcon from "@/components/BIcon";
import firebaseAuth from "@/plugins/firebase/auth";
import SuscriptionModal from "@/components/SuscriptionModal/SuscriptionModal";
import MenuItem from "./components/MenuItem";
import DolarSignIcon from "@/assets/icons/dollar-sign-solid.svg";
import RightFromBracketIcon from "@/assets/icons/right-from-bracket-solid.svg";
import utils from "@/utils/functions";
import NotificationsApi from "../services/notifications";
import NotificationItem from "../components/Notification/NotificationItem.vue";
import OrderDetailsModal from "../components/OrderDetailsModal.vue";
import OrdersApi from "../services/orders";
import { mapState } from "vuex";
let unsubscriberBg = () => undefined;
let unsubscriberFg = () => undefined;
const VAPID_KEY =
  "BDVvF9i37nYVj2HBgeT0S4V8J6sYTMnAZbMIgWll875yD220q9pzZ-tiuAGp3fZuLqlIeROrs9b-NbV59X-Dpto";
const getMenuOptions = vm => [
  {
    id: "suscription",
    srcIcon: DolarSignIcon,
    textOption: vm.$t("main_layout_topbar.menu_items.subscription"),
    onClickHandler: vm.handleOpenSuscriptionModal
  },
  {
    id: "logout",
    srcIcon: RightFromBracketIcon,
    textOption: vm.$t("main_layout_topbar.menu_items.log_out"),
    onClickHandler: vm.logOut,
    itemColorStyle: { color: "red" }
  }
];
export default {
  name: "MainLayout",
  components: {
    BIcon,
    SuscriptionModal,
    MenuItem,
    NotificationItem,
    OrderDetailsModal
  },
  props: {
    navTitle: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    const vm = this;
    return {
      showMenu: false,
      userImageError: false,
      openModal: false,
      menuOptions: getMenuOptions(vm),
      showNotifications: false,
      selectedOrder: {},
      isModalVisible: false
    };
  },
  computed: {
    ...mapState(["loading", "notifications"]),
    formatedNotifications() {
      const frmtNotifications = this.notifications
        .filter(notification => notification.active)
        .map(notification => {
          let title = this.$t(
            `admin_notifications.order_status.${notification?.data?.orderStatus}.title`
          );
          let body = this.$t(
            `admin_notifications.order_status.${notification?.data?.orderStatus}.body`
          );
          let tmpBody = body
            .replace(/{{customerName}}/, notification?.data?.customerName)
            .replace(/{{orderId}}/, notification?.data?.orderId);
          return {
            id: notification._id,
            title,
            websiteName: notification?.data?.websiteName,
            body: tmpBody,
            status: notification?.data?.orderStatus,
            visited: notification.visited
          };
        });
      return frmtNotifications;
    },
    showBadge() {
      return this.formatedNotifications.some(ntf => !ntf.visited);
    },
    userProfileImage() {
      return this.userImageError
        ? require("@/assets/images/user.png")
        : this.$store.state.user.providerData[0].photoURL;
    },
    openSubscriptionModal: {
      get() {
        const { query } = this.$route;
        const selectedMenuOption = query?.selectedMenuOption;
        return selectedMenuOption === "subscription";
      },
      set(val) {
        const option = val ? "subscription" : "";
        this.setMenuOption(option);
      }
    }
  },
  bedoreDestroy() {
    unsubscriberBg();
    unsubscriberFg();
  },
  mounted() {
    this.requestPermissions();
    this.messageListenerBridge(payload => {
      const body = {
        active: JSON.parse(payload?.data?.active),
        body: payload?.notification?.title,
        createdAt: payload?.data?.createdAt,
        data: {
          orderId: parseInt(payload?.data?.orderId),
          uOrderId: payload?.data?.uOrderId,
          orderStatus: payload?.data?.orderStatus,
          websiteId: payload?.data?.websiteId,
          websiteName: payload?.data?.websiteName,
          adminId: payload?.data?.adminId
        },
        notificationType: payload?.data?.notificationType,
        title: payload?.notification?.title,
        adminId: payload?.data?.adminId,
        visited: JSON.parse(payload?.data?.visited),
        _id: payload?.data?.notificationId
      };
      this.$store.commit("ADD_NEW_NOTIFICATION", body);
    });
    this.$store.dispatch("getNotifications");
  },
  methods: {
    messageListenerBridge(cb) {
      unsubscriberBg = utils.onBackgroundMessage(cb);
      unsubscriberFg = messaging.onMessage(cb);
    },
    closeOrderDetailsModal() {
      this.isModalVisible = false;
    },
    async requestPermissions() {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const firebaseToken = await messaging.getToken(messaging, {
            vapidKey: VAPID_KEY
          });
          await NotificationsApi.saveNotificationToken({
            firebaseToken
          });
          return true;
        }
      } catch (error) {
        return false;
      }
    },
    handleClickCloseNotification(notificationId) {
      const body = { notificationId, active: false, visited: true };
      this.$store.dispatch("updateNotification", body);
    },
    async handleClickNotification(notificationId) {
      try {
        const notification = this.notifications.find(
          item => item._id === notificationId
        );
        if (notification) {
          const {
            data: { uOrderId }
          } = notification;
          this.$store.dispatch("updateNotification", {
            notificationId,
            visited: true
          });
          const responseOrder = await OrdersApi.getOrderDetails(uOrderId);
          if (responseOrder.status === 200) {
            this.selectedOrder = responseOrder.data.data;
            this.isModalVisible = true;
          }
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    handleClickOpenNotificacions() {
      this.showNotifications = !this.showNotifications;
      this.$nextTick(() => {
        this.$refs.notificationsList.focus();
      });
    },
    closeNotifications() {
      this.showNotifications = false;
    },
    setMenuOption: function setMenuOption(option = "") {
      const validOptions = ["", "subscription"];
      const { query } = this.$route;
      let history = {};
      if (validOptions.includes(option)) {
        if (option === "") {
          const tmpQuery = utils.omitKeys(query, [
            "selectedMenuOption",
            "subscriptionId",
            "editOption"
          ]);
          history = { name, query: tmpQuery };
        } else {
          const selectedMenuOption = option;
          history = {
            query: {
              ...query,
              selectedMenuOption
            }
          };
        }
        this.$router.replace(history);
      }
    },
    async logOut() {
      try {
        await firebaseAuth.signOut();
        ApiAuth.removeUID();
        this.$router.push({ name: "signin" }).catch(() => {});
      } catch (_) {
        console.log();
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.$nextTick(() => {
        this.$refs.userMenu.focus();
      });
    },
    closeMenu() {
      this.showMenu = false;
    },
    handleOpenSuscriptionModal() {
      this.openSubscriptionModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.button-container {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.badge {
  position: absolute;
  left: calc(100% - 4px);
  top: -4px;
  z-index: 2;
  text-wrap: nowrap;
  padding: 4px;
  background-color: rgb(0, 89, 255);
  color: white;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 0.7rem;
  user-select: none;
  transition: all 0.2s ease-in-out;
}

.img-icon {
  height: 24px;
}
.profile_menu {
  max-width: 223px;
}
.menu_item {
  width: 200px;
}

.main-layout {
  height: 100vh;
}

.cls-notifications-list {
  top: 40px;
  width: 350px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: -1;
  max-height: 400px;
  overflow-y: scroll;
  &:focus {
    border: 0;
  }
}

.notification-divider {
  border-bottom: 2px solid #eaeaea;
}

.empty-not-message {
  font-weight: bold;
  color: #72737b;
  font-size: 1.1rem;
}
</style>
